import './kitchen.css';

import NavBar from '../../components/navBar/navBar.js';
import KitchenBar from '../../components/kitchenBar/kitchenBar.js';
import HeaderBar from '../../components/headerBar.js';

import dish01 from '../../assets/dishes/chickenrice.png';
import dish02 from '../../assets/dishes/chillicrab.png';
import dish03 from '../../assets/dishes/prawnnoodle.png';
import dish04 from '../../assets/dishes/bakkutteh.png';
import dish05 from '../../assets/dishes/curryfishhead.png';

import item01a from '../../assets/ingredients/01_Chicken.png';
import item01b from '../../assets/ingredients/02_Garlic.png';
import item01c from '../../assets/ingredients/11_ChickenStock.png';
import item01d from '../../assets/ingredients/16_Rice.png';
import item01e from '../../assets/ingredients/17_SoySauce.png';

import item02a from '../../assets/ingredients/03_Crab.png';
import item02b from '../../assets/ingredients/04_Chilli.png';
import item02c from '../../assets/ingredients/12_SteamedBun.png';
import item02d from '../../assets/ingredients/18_Egg.png';
import item02e from '../../assets/ingredients/19_Oil.png';


import item03a from '../../assets/ingredients/05_Prawn.png';
import item03b from '../../assets/ingredients/06_BeanSprouts.png';
import item03c from '../../assets/ingredients/13_FriedLard.png';
import item03d from '../../assets/ingredients/21_Salt.png';
import item03e from '../../assets/ingredients/22_Noodle.png';

import item04a from '../../assets/ingredients/07_PorkRibs.png';
import item04b from '../../assets/ingredients/08_SaltedVegetable.png';
import item04c from '../../assets/ingredients/14_FriedFritter.png';
import item04d from '../../assets/ingredients/16_Rice.png';
import item04e from '../../assets/ingredients/20_Pepper.png';


import item05a from '../../assets/ingredients/09_Fish.png';
import item05b from '../../assets/ingredients/10_Tomato.png';
import item05c from '../../assets/ingredients/15_CoconutMilk.png';
import item05d from '../../assets/ingredients/19_Oil.png';
import item05e from '../../assets/ingredients/21_Salt.png';

import cashIcon from '../../assets/sprite_0.png';

import keyIcon from '../../assets/items/key.png';
import tokenIcon from '../../assets/items/adventoken.png';


import { useEffect, useState } from 'react';
import axios from 'axios';

function Kitchen() {
    // Player Info
    const [username] = useState(() => {
        return sessionStorage.getItem("username");
    });

    // Adventure Status Information
    // const [region, setRegion] = useState(1);

    const [items, setItems] = useState([]);

    const handleCookDish = async () => {
        alert("Temporarily Disabled");
    }

    // Update the data at initial load (Once)
    const fetchAPIData = async () => {
        if (!username) return console.error("Username not found in session.");

        try {
            const response = await axios.get(`/api/kitchen/status?username=${username}`);
            console.log(response)
            if (response.data.success) {
                setItems(response.data.items);
            }
        } catch {
            console.log("error");
        }
    };

    useEffect(() => {
        fetchAPIData();
    });

    return (
        <div className="kitchenPage">
            <HeaderBar header={"Kitchen"} />
            {/* Chicken Rice */}
            <div className="kitchenPage-dishCardBorder">
                <div className="kitchenPage-dishCard">
                    <div className="kitchenPage-dishCardHeader">Chicken Rice</div>
                    <div className="kitchenPage-dishCardDetail">
                        <img className="kitchenPage-dishCardIcon" src={dish01} alt="Home" />
                        <div className="kitchenPage-dishCardInfo">
                            <div className="kitchenPage-dishCardInfo-recipeInfo">Recipe
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>5</b> Chicken</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>5</b> Garlic</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>1</b> Chicken Stock</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>7</b> Rice</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>10</b> Soy Sauce</span>
                            </div>
                        </div>
                    </div>
                    <div className="kitchenPage-dishCardFooter">
                        <div className="kitchenPage-dishCardFooter-recipeCount">
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item01a} alt="Home" />{items.Chicken}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item01b} alt="Home" />{items.Garlic}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item01c} alt="Home" />{items.ChickenStock}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item01d} alt="Home" />{items.Rice}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item01e} alt="Home" />{items.SoySauce}
                            </div>
                        </div>
                        <button className="kitchenPage-joinZoneButton" onClick={() => handleCookDish(1)} type="button">Cook Dish</button>
                    </div>
                </div>
            </div>
            {/* Chilli Crab */}
            <div className="kitchenPage-dishCardBorder">
                <div className="kitchenPage-dishCard">
                    <div className="kitchenPage-dishCardHeader">Chilli Crab</div>
                    <div className="kitchenPage-dishCardDetail">
                        <img className="kitchenPage-dishCardIcon" src={dish02} alt="Home" />
                        <div className="kitchenPage-dishCardInfo">
                            <div className="kitchenPage-dishCardInfo-recipeInfo">Recipe
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>5</b> Crab</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>5</b> Chilli</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>1</b> Steamed Bun</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>5</b> Egg</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>3</b> Oil</span>
                            </div>
                        </div>
                    </div>
                    <div className="kitchenPage-dishCardFooter">
                        <div className="kitchenPage-dishCardFooter-recipeCount">
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item02a} alt="Home" />{items.Chicken}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item02b} alt="Home" />{items.Garlic}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item02c} alt="Home" />{items.ChickenStock}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item02d} alt="Home" />{items.Rice}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item02e} alt="Home" />{items.SoySauce}
                            </div>
                        </div>
                        <button className="kitchenPage-joinZoneButton" onClick={() => handleCookDish(1)} type="button">Cook Dish</button>
                    </div>
                </div>
            </div>
            {/* Prawn Noodle */}
            <div className="kitchenPage-dishCardBorder">
                <div className="kitchenPage-dishCard">
                    <div className="kitchenPage-dishCardHeader">Prawn Noodle</div>
                    <div className="kitchenPage-dishCardDetail">
                        <img className="kitchenPage-dishCardIcon" src={dish03} alt="Home" />
                        <div className="kitchenPage-dishCardInfo">
                            <div className="kitchenPage-dishCardInfo-recipeInfo">Recipe
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>5</b> Prawn</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>5</b> Bean Sprout</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>1</b> Fried Lard</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>5</b> Salt</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>5</b> Noodle</span>
                            </div>
                        </div>
                    </div>
                    <div className="kitchenPage-dishCardFooter">
                        <div className="kitchenPage-dishCardFooter-recipeCount">
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item03a} alt="Home" />{items.Chicken}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item03b} alt="Home" />{items.Garlic}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item03c} alt="Home" />{items.ChickenStock}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item03d} alt="Home" />{items.Rice}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item03e} alt="Home" />{items.SoySauce}
                            </div>
                        </div>
                        <button className="kitchenPage-joinZoneButton" onClick={() => handleCookDish(1)} type="button">Cook Dish</button>
                    </div>
                </div>
            </div>
            {/* Bak Kut Teh */}
            <div className="kitchenPage-dishCardBorder">
                <div className="kitchenPage-dishCard">
                    <div className="kitchenPage-dishCardHeader">Bak Kut Teh</div>
                    <div className="kitchenPage-dishCardDetail">
                        <img className="kitchenPage-dishCardIcon" src={dish04} alt="Home" />
                        <div className="kitchenPage-dishCardInfo">
                            <div className="kitchenPage-dishCardInfo-recipeInfo">Recipe
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>5</b> Pork Ribs</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>5</b> Salted Vegetable</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>1</b> Fried Fritter</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>3</b> Rice</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>10</b> Pepper</span>
                            </div>
                        </div>
                    </div>
                    <div className="kitchenPage-dishCardFooter">
                        <div className="kitchenPage-dishCardFooter-recipeCount">
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item04a} alt="Home" />{items.Chicken}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item04b} alt="Home" />{items.Garlic}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item04c} alt="Home" />{items.ChickenStock}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item04d} alt="Home" />{items.Rice}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item04e} alt="Home" />{items.SoySauce}
                            </div>
                        </div>
                        <button className="kitchenPage-joinZoneButton" onClick={() => handleCookDish(1)} type="button">Cook Dish</button>
                    </div>
                </div>
            </div>
            {/* Curry Fish Head */}
            <div className="kitchenPage-dishCardBorder">
                <div className="kitchenPage-dishCard">
                    <div className="kitchenPage-dishCardHeader">Curry Fish Head</div>
                    <div className="kitchenPage-dishCardDetail">
                        <img className="kitchenPage-dishCardIcon" src={dish05} alt="Home" />
                        <div className="kitchenPage-dishCardInfo">
                            <div className="kitchenPage-dishCardInfo-recipeInfo">Recipe
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>5</b> Fish</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>5</b> Tomato</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>1</b> Coconut Milk</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>10</b> Oil</span>
                                <span className="kitchenPage-dishCardInfo-recipeInfoText"><b>10</b> Salt</span>
                            </div>
                        </div>
                    </div>
                    <div className="kitchenPage-dishCardFooter">
                        <div className="kitchenPage-dishCardFooter-recipeCount">
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item05a} alt="Home" />{items.Chicken}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item05b} alt="Home" />{items.Garlic}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item05c} alt="Home" />{items.ChickenStock}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item05d} alt="Home" />{items.Rice}
                            </div>
                            <div className="kitchenPage-dishCardFooter-itemBlock">
                                <img className="kitchenPage-dishCardFooter-itemIcon" src={item05e} alt="Home" />{items.SoySauce}
                            </div>
                        </div>
                        <button className="kitchenPage-joinZoneButton" onClick={() => handleCookDish(1)} type="button">Cook Dish</button>
                    </div>
                </div>
            </div>
            <NavBar />
        </div >
    );
}

export default Kitchen;
