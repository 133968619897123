import './quest.css';

import NavBar from '../../components/navBar/navBar.js';
import QuestBar from '../../components/questBar/questBar.js';

import dish01 from '../../assets/dishes/chickenrice.png';

import item01a from '../../assets/ingredients/01_Chicken.png';
import item01b from '../../assets/ingredients/02_Garlic.png';
import item01c from '../../assets/ingredients/11_ChickenStock.png';
import item01d from '../../assets/ingredients/16_Rice.png';
import item01e from '../../assets/ingredients/17_SoySauce.png';

import cashIcon from '../../assets/sprite_0.png';

import keyIcon from '../../assets/items/key.png';
import tokenIcon from '../../assets/items/adventoken.png';


import { useEffect, useState } from 'react';
import axios from 'axios';

function Adventure() {
    // Player Info
    const [username] = useState(() => {
        return sessionStorage.getItem("username");
    });

    // Adventure Status Information
    // const [region, setRegion] = useState(1);

    const [items, setItems] = useState([]);

    const handleCookDish = async () => {
        alert("Temporarily Disabled");
    }

    // Update the data at initial load (Once)
    const fetchAPIData = async () => {
        if (!username) return console.error("Username not found in session.");

        try {
            const response = await axios.get(`/api/kitchen/status?username=${username}`);
            console.log(response)
            if (response.data.success) {
                setItems(response.data.items);
            }
        } catch {
            console.log("error");
        }
    };

    useEffect(() => {
        fetchAPIData();
    });

    return (
        <div className="questPage">
            <QuestBar />
            <div className="questPage-questCardBorder">
                <div className="questPage-questCard">
                    <div className="questPage-questCardHeader">Monthly</div>
                    <div className="questPage-questCardDetail">
                        <div className="questPage-questCardInfoBorder">
                            <div className="questPage-questCardInfo">
                                <div className="questPage-questCardInfo-questName">Clear 100,000 Adventures</div>
                                <div className="questPage-questCardInfo-questReward">Gold x1</div>
                            </div>
                            <div className="questPage-questCardInfo">
                                <div className="questPage-questCardInfo-questName">Win 1,000 Dungeons</div>
                                <div className="questPage-questCardInfo-questReward">Gold x1</div>
                            </div>
                            <div className="questPage-questCardInfo">
                                <div className="questPage-questCardInfo-questName">Cook 1,000 Dishes</div>
                                <div className="questPage-questCardInfo-questReward">Gold x1</div>
                            </div>
                            <div className="questPage-questCardInfo">
                                <div className="questPage-questCardInfo-questName">Serve 100 Orders</div>
                                <div className="questPage-questCardInfo-questReward">Gold x1</div>
                            </div>
                            <div className="questPage-questCardInfo">
                                <div className="questPage-questCardInfo-questName">Complete 100 Quests</div>
                                <div className="questPage-questCardInfo-questReward">Gold x1</div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            <NavBar />
        </div >
    );
}

export default Adventure;
