import { useState, useEffect } from 'react';
import '../../assets/style.css';

import NavBar from '../../components/navBar/navBar.js';
import ProfileBar from '../../components/profileBar/profileBar.js';
import AdventureCard from '../../components/adventureHome/adventureHome.js';

import HeaderBar from '../../components/headerBar.js';

function Home() {

    const [username] = useState(() => {
        return sessionStorage.getItem('username');
    });

    const FirecrackerOverlay = () => {
        useEffect(() => {
            const createFallingFirecracker = (x) => {
                const firecracker = document.createElement('div');
                firecracker.className = 'falling-firecracker';
                firecracker.style.left = `${x}px`;
                // firecracker.style.top = `-50px`;

                for (let i = 0; i < 10; i++) {
                    const spark = document.createElement('div');
                    spark.className = 'spark';
                    firecracker.appendChild(spark);

                    const angle = Math.random() * 2 * Math.PI;
                    const distance = Math.random() * 30 + 50;
                    const dx = Math.cos(angle) * distance;
                    const dy = Math.sin(angle) * distance;

                    spark.style.setProperty('--dx', dx);
                    spark.style.setProperty('--dy', dy);
                }

                const overlay = document.getElementById('firecracker-overlay');
                if (overlay) {
                    overlay.appendChild(firecracker);

                    setTimeout(() => {
                        firecracker.remove();
                    }, 3000);
                }
            };
            const generateFallingFirecrackers = () => {
                const overlay = document.getElementById('firecracker-overlay');
                if (overlay) {
                    const { offsetWidth } = overlay;
                    const x = Math.random() * offsetWidth;
                    createFallingFirecracker(x);
                }
            };
            const interval = setInterval(generateFallingFirecrackers, 500);

            return () => clearInterval(interval);
        }, []);

        return null;
    };

    return (
        <div className="deviceScreenBorder">
            <HeaderBar header={username} />
            <div id="firecracker-overlay" className="eventOverlay"></div>
            <FirecrackerOverlay />
            <a href="/event" className="homeCard-1 seasonHome">
                <div className="homeCard-Detail">
                    <h1>Event 1</h1>
                    <h2>Chinese New Year 2025</h2>
                </div>
            </a>
            <AdventureCard />
            {/* <a href="/adventure" className="homeCard-3 adventureHome">
                <div className="homeCard-Detail">
                    <h1>Adventure</h1>
                </div>
            </a> */}
            <a href="/battle" className="homeCard-3 battleHome">
                <div className="homeCard-Detail">
                    <h1>Battle</h1>
                </div>
            </a>
            <a href="/kitchen" className="homeCard-3 kitchenHome">
                <div className="homeCard-Detail">
                    <h1>Kitchen</h1>
                </div>
            </a>
            <a href="/order" className="homeCard-3 orderHome">
                <div className="homeCard-Detail">
                    <h1>Orders</h1>
                </div>
            </a>
            <a href="/quest" className="homeCard-3 questHome">
                <div className="homeCard-Detail">
                    <h1>Quests</h1>
                </div>
            </a>
            <a href="/headquarters" className="homeCard-3 parcelHome">
                <div className="homeCard-Detail">
                    <h1>Parcels</h1>
                </div>
            </a>
            <a href="/guides" className="homeCard-1 guideHome">
                <div className="homeCard-Detail">
                    <h1>Official Guide</h1>
                </div>
            </a>
            <NavBar />
        </div>
    );
}

export default Home;