import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './adventureHome.css';
import '../../assets/style.css';

function AdventureCard() {

    let zoneNames = ["Unavailable", "Zone 1 - Clucky", "Zone 2 - Garly", "Zone 3 - Crabby", "Zone 4 - Chilly"];

    const [username] = useState(() => {
        return sessionStorage.getItem('username');
    });

    const [zone, setZone] = useState(0);
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [maxTime, setMaxTime] = useState(7200);

    // Update the data at initial load (Once)
    const fetchAPIData = async () => {
        if (!username) return console.error("Username not found in session.");

        try {
            const response = await axios.get(`/api/menu/adventure?username=${username}`);
            if (response.data.success) {
                setZone(response.data.data.zoneNo);
                setTimeElapsed(response.data.data.timeElapsed);
                setMaxTime(response.data.data.maxTime);
            }
        } catch {
            console.log("error");
        }
    };

    useEffect(() => {
        fetchAPIData();
    });

    // UseEffect for the live updating progress bar and idleKills update
    useEffect(() => {
        const interval = setInterval(() => {
            // Only update time if the player is in a valid zone
            if (zone > 0) {  // Only count time if in a valid zone (zone > 0)
                setTimeElapsed(prevTime => {
                    if (prevTime < maxTime) {  // Update until 2 hours (Idle capped)
                        return prevTime + 1;
                    }
                    clearInterval(interval); // Stop interval once we reach the capped time
                    return prevTime;
                });
            } else {
                // If the player is in an invalid zone (zone == 0), set time to 0
                setTimeElapsed(0);
            }
        }, 1000); // Runs every second

        // Cleanup on component unmount
        return () => clearInterval(interval);
    }, [timeElapsed, zone, maxTime]);

    function timeFormatter(seconds) {
        if (seconds === 0) {
            return "No Adventure Ongoing"
        } else if (seconds < 60) {
            return "0:" + ((seconds < 10) ? "0" + seconds : seconds);
        } else if (seconds < (3600)) {
            return Math.floor(seconds / 60) + ":" + ((seconds % 60 < 10) ? "0" + seconds % 60 : seconds % 60);
        } else if (seconds < maxTime) {
            return Math.floor(seconds / 3600) + ":" + (((Math.floor(seconds % 3600 / 60)) < 10) ? "0" + Math.floor(seconds % 3600 / 60) : Math.floor(seconds % 3600 / 60)) + ":" + ((seconds % 60 < 10) ? "0" + seconds % 60 : seconds % 60);
        } else {
            return Math.floor(maxTime / 3600) + "h (Idle Capped)";
        }
    }

    return (
        <a href="/adventure" className="homeCard-3 adventureHome">
            <div className="adventureCardInfo">
                <h1>Adventure</h1>
                <h2>{zoneNames[zone]}</h2>
                <div className="progressBar">
                    <div className="progressIdle" style={{ width: (timeElapsed / maxTime >= 1 || timeElapsed === 0) ? "100%" : `${(timeElapsed * 100) / maxTime}%`, backgroundColor: (timeElapsed / maxTime >= 1 || timeElapsed === 0) ? "#FF0000" : "", color: (timeElapsed / maxTime >= 1 || timeElapsed === 0) ? "#FFFFFF" : "" }}>{timeFormatter(timeElapsed)}</div>
                </div>
            </div>
        </a>
    );
}

export default AdventureCard;
