import React, { useState, useEffect } from 'react';
import axios from 'axios';

import goldIcon from '../../assets/sprite_1.png';

import './questBar.css';

function KitchenBar() {

    const [username] = useState(() => {
        return sessionStorage.getItem('username');
    });

    // const [level, setLevel] = useState(0);
    // const [xp, setXP] = useState(0);
    // const [region, setRegion] = useState(0);
    // const [cash, setCash] = useState(0);
    const [gold, setGold] = useState(0);

    const fetchAPIData = async () => {
        if (!username) {
            console.error("Username not found in sessionStorage");
            return;
        }

        try {
            const response = await axios.get(`/api/menu/header?username=${username}`);
            if (response.data.success) {
                // setLevel(response.data.data.playerLvl);
                // setXP(response.data.data.playerExp);
                // setRegion(response.data.data.regionNo);
                // setCash(response.data.data.regionCash);
                setGold(response.data.data.premium);
            }
        } catch (error) {
            console.error("Error", error);
        }
    };

    useEffect(() => {
        fetchAPIData();
    });

    return (
        <div className='kitchenBar'>
            <div className="kitchenBar-title">
                Quests
            </div>
            <div className="kitchenBar-cash">
                <img className="kitchenBar-cash-icon" src={goldIcon} alt="R1Icon" />
                <div className="kitchenBar-cash-text">{gold}</div>
            </div>
        </div>
    );
}

export default KitchenBar;