import './order.css';

import NavBar from '../../components/navBar/navBar.js';
import OrderBar from '../../components/orderBar/orderBar.js';

import dish01 from '../../assets/dishes/chickenrice.png';

import item01a from '../../assets/ingredients/01_Chicken.png';
import item01b from '../../assets/ingredients/02_Garlic.png';
import item01c from '../../assets/ingredients/11_ChickenStock.png';
import item01d from '../../assets/ingredients/16_Rice.png';
import item01e from '../../assets/ingredients/17_SoySauce.png';

import cashIcon from '../../assets/sprite_0.png';

import keyIcon from '../../assets/items/key.png';
import tokenIcon from '../../assets/items/adventoken.png';


import { useEffect, useState } from 'react';
import axios from 'axios';

function Adventure() {
    // Player Info
    const [username] = useState(() => {
        return sessionStorage.getItem("username");
    });

    // Adventure Status Information
    // const [region, setRegion] = useState(1);

    const [orders, setOrders] = useState([]);

    const handleCookDish = async () => {
        alert("Temporarily Disabled");
    }

    // Update the data at initial load (Once)
    const fetchAPIData = async () => {
        if (!username) return console.error("Username not found in session.");

        try {
            const response = await axios.get(`/api/order/getOrder?username=${username}`);
            console.log(response)
            if (response.data.success) {
                setOrders(response.data.orderList);
            }
        } catch {
            console.log("error");
        }
    };

    useEffect(() => {
        fetchAPIData();
    }, [username]);

    return (
        <div className="orderPage">
            <OrderBar />
            {orders.map((item, index) => (
                <div key={index} className="orderPage-questCardBorder">
                    <div className="orderPage-questCard">
                        <div className="orderPage-questCardHeader">Order #{item.ID}</div>
                        <div className="orderPage-questCardDetail">
                            {item.Requirement.map((value, index) => {
                                // Define an array of dishes
                                const dishes = [
                                    "Chicken Rice",
                                    "Chilli Crab",
                                    "Prawn Noodle",
                                    "Bak Kut Teh",
                                    "Curry Fish Head"
                                ];

                                return value === 1 ? (
                                    <div className="orderPage-questCardInfoBorder">
                                        <div className="orderPage-questCardInfo">
                                            <div className="orderPage-questCardInfo-questName">
                                                1 {dishes[index]}
                                            </div>
                                        </div>
                                    </div>
                                ) : null; // Render nothing if the value is not 1
                            })}
                        </div>
                        <button>Refresh</button>
                        <button>Serve</button>
                    </div>
                </div>
            ))
            }

            {/* <div className="orderPage-questCardBorder">
                <div className="orderPage-questCard">
                    <div className="orderPage-questCardHeader">Order Slot 1{orders}</div>
                    <div className="orderPage-questCardDetail">
                        <div className="orderPage-questCardInfoBorder">
                            <div className="orderPage-questCardInfo">
                                <div className="orderPage-questCardInfo-questName">1 Chicken Rice</div>
                            </div>
                        </div>
                    </div>
                    <button>Refresh</button>
                    <button>Serve</button>
                </div>
            </div> */}
            <NavBar />
        </div >
    );
}

export default Adventure;
