import logo from '../assets/01_Clucky.png';
import NavBar from '../components/navBar/navBar';
import '../Maintenance.css';


import { useState, useEffect } from 'react';
import axios from 'axios';

import HeaderBar from '../components/headerBar';

function ComingSoon() {
  const [username] = useState(() => sessionStorage.getItem("username"));
  const [eventCoins, setEventCoins] = useState(0);

  const fetchAPIData = async () => {
    if (!username) return console.error("Username not found in session.");

    try {
      const response = await axios.get(`/api/event/status?username=${username}`);
      if (response.data.success) {
        // setRegion(response.data.data.regionNo);
        setEventCoins(response.data.data.EventCoins);
      }
    } catch {
      console.log("error");
    }
  };

  useEffect(() => {
    fetchAPIData();
  });

  return (
    <div className="deviceScreenBorder">
      <HeaderBar header={"Event"} />
      <div className="comingSoonScreen">
        <img src={logo} className="comingSoonLogo" alt="logo" />
        <h2 style={{ color: "white" }}>Event is currently in preparation. Check back soon!</h2>
        <h2 style={{ color: "black" }}>You have {eventCoins} Event Coins!</h2>
      </div>
      <NavBar />
    </div>
  );
}

export default ComingSoon;
