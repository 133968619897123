import { useEffect, useState } from 'react';
import axios from 'axios';

import './battlefield.css';

import HeaderBar from '../../components/headerBar';

import region01Boss from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';

function Battlefield() {
    const navigate = useNavigate();
    const [username] = useState(() => { return sessionStorage.getItem("username") });

    const [round, setRound] = useState(0);
    const [lastEnemyAction, setLastEnemyAction] = useState("");
    const [lastPlayerAction, setLastPlayerAction] = useState("");
    const [playerHP, setPlayerHP] = useState(100);
    const [enemyHP, setEnemyHP] = useState(100);

    const updateGameStatus = async (action) => {
        try {
            const response = await axios.post(`/api/dungeon/update`, { username, action });
            if (response.data.success) {
                if (!response.data.complete) {
                    setRound(response.data.game.round);
                    setEnemyHP(response.data.enemy.health);
                    setLastEnemyAction(response.data.enemy.lastAction);

                    setPlayerHP(response.data.player.health);
                    setLastPlayerAction(response.data.player.lastAction);
                } else {
                    sessionStorage.setItem("isInDungeon", false);
                    if (response.data.player.health <= 0) {
                        alert("Game Complete. You Lost.");
                        navigate("/battle");
                    } else {
                        handleWinState();
                    }
                }
            }
        } catch (error) {
            console.error("Error:", error.response || error.message || error);
            navigate("/battle");
        }
    };

    const handleWinState = async (action) => {
        try {
            const response = await axios.post(`/api/dungeon/handleWin`, { username });
            if (response.data.success) {
                alert(`Dungeon Complete! \nYou killed the Tier 1 Boss \nTotal Cash: ${response.data.reward.cash} \nTotal XP: ${response.data.reward.xp} \n${response.data.reward.ingredient}: 1 \nRegion 1 Parcel (Tier 1): ${response.data.reward.parcel} \nRegion 1 Token: ${response.data.reward.token}`)
            }
            navigate("/battle");
        } catch (error) {
            console.error("Error:", error.response || error.message || error);
            navigate("/battle");
        }
    };

    useEffect(() => {
        updateGameStatus(-1);
    });

    return (
        <div className="battlefieldScreen">
            <HeaderBar header={"Battlefield"} />
            <div className="battlefieldHeader">Dungeon</div>
            <div class="battlefieldEnemy">
                <h2>Region 1 Boss</h2>
                <img className="enemyIcon" src={region01Boss} alt="Boss" />
                <div className="healthBarBox">
                    <h3>HP</h3>
                    <div className="healthBase">
                        <div className="healthBar" style={{ width: `${enemyHP}%` }}>.</div>
                    </div>
                    <h3>{enemyHP}%</h3>
                </div>
                <h3>{(round > 0) ? `[ Round ${round} ] Boss used ${lastEnemyAction}.` : ""}</h3>
            </div>
            <div class="battlefieldPlayer">
                <h2>You</h2>
                <div className="healthBarBox">
                    <h3>HP</h3>
                    <div className="healthBase">
                        <div className="healthBar" style={{ width: `${playerHP}%` }}>.</div>
                    </div>
                    <h3>{playerHP}%</h3>
                </div>
                <h3>{(round > 0) ? `[ Round ${round} ] You used ${lastPlayerAction}.` : ""}</h3>
            </div>
            <div class="battlefieldAction">
                <h2>Action Bar</h2>
                <h3>{`Water > Fire  > Earth > Wind > Water`}</h3>
                <div className="actionGrid">
                    <button className="actionButton" onClick={() => updateGameStatus(0)} type="button">Water</button>
                    <button className="actionButton" onClick={() => updateGameStatus(1)} type="button">Fire</button>
                    <button className="actionButton" onClick={() => updateGameStatus(2)} type="button">Earth</button>
                    <button className="actionButton" onClick={() => updateGameStatus(3)} type="button">Wind</button>
                </div>
            </div>
        </div>
    );
}

export default Battlefield;
