import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './market.css';

import NavBar from '../../components/navBar/navBar.js';
import MarketBar from '../../components/marketBar/marketBar.js';
import ProfileBar from '../../components/profileBar/profileBar.js';
import HeaderBar from '../../components/headerBar.js';

import cashIcon from '../../assets/sprite_0.png';

import riceIcon from '../../assets/ingredients/16_Rice.png';
import soySauceIcon from '../../assets/ingredients/17_SoySauce.png';
import eggIcon from '../../assets/ingredients/18_Egg.png';
import oilIcon from '../../assets/ingredients/19_Oil.png';
import pepperIcon from '../../assets/ingredients/20_Pepper.png';
import saltIcon from '../../assets/ingredients/21_Salt.png';
import noodleIcon from '../../assets/ingredients/22_Noodle.png';

function Market() {
    const navigate = useNavigate();

    const [username] = useState(() => sessionStorage.getItem("username"));
    const [quantity, setQuantity] = useState(1);
    const [cash, setCash] = useState(0);

    const [showReceipt, setShowReceipt] = useState(false);
    const [purchasedItem, setPurchasedItem] = useState([]);

    const buyFuel = async (quantity) => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/market/buyFuel`, { username, quantity });
            if (response.data.success) {
                alert(`Thank you for your purchase! We have added ${quantity} Forge Fuel into your inventory!`);
                navigate("/market");
            } else if (!response.data.success) {
                alert(`Sorry :( \nYou do not have enough Cash to purchase the item.`);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };

    const buyCloth = async (quantity) => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/market/buyCloth`, { username, quantity });
            if (response.data.success) {
                alert(`Thank you for your purchase! We have added ${quantity} Polishing Cloth into your inventory!`);
                navigate("/market");
            } else if (!response.data.success) {
                alert(`Sorry :( \nYou do not have enough Cash to purchase the item.`);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };

    const buyParcelR1T1 = async (quantity) => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/market/buyParcelR1T1`, { username, quantity });
            if (response.data.success) {
                alert(`Thank you for your purchase! We have added ${quantity} Region 1 (Tier 1) Parcel into your inventory!`);
                navigate("/market");
            } else if (!response.data.success) {
                alert(`Sorry :( \nYou do not have enough Cash to purchase the item.`);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };

    const buyItem = async (itemId, quantity) => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.put(`/api/market/buy`, { username, itemId, quantity });
            if (response.data.success) {
                setCash(response.data.data.balance);
                setPurchasedItem(response.data.data);
                setShowReceipt(true);
                // alert(`Thank you for your purchase! \nWe have added ${response.data.data.itemQty} ${response.data.data.itemName} into your inventory! \nYou spent ${response.data.data.totalCost} and your balance is ${response.data.data.balance.toFixed(2)}!`);
            } else if (!response.data.success) {
                alert(`Sorry :( \nYou do not have enough Cash to purchase the item.`);
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    }

    const fetchAPIData = async () => {
        if (!username) {
            console.error("Username not found in session.");
            return;
        }

        try {
            const response = await axios.get(`/api/profile/getInfo?username=${username}`);
            if (response.data.success) {
            }
        } catch (error) {
            console.error("Error fetching inventory data:", error);
        }
    };
    // Fetch inventory data
    useEffect(() => {
        fetchAPIData();
    }, [cash]);

    return (
        <div className="profileScreen">
            <HeaderBar header={"Market"} />
            {showReceipt &&
                <div className="market-receipt-screen">
                    <div className="market-receipt-popup">
                        <div className="market-receipt-header">Receipt</div>
                        <div className="market-receipt-item">
                            <div className="market-receipt-item-header">
                                <div className="market-receipt-item-header-text">Item</div>
                                <div className="market-receipt-item-header-text">Qty</div>
                                <div className="market-receipt-item-header-text">Unit Price</div>
                            </div>
                            <div className="market-receipt-item-description">
                                <div className="market-receipt-item-description-text">{purchasedItem.itemName}</div>
                                <div className="market-receipt-item-description-text">{purchasedItem.itemQty}</div>
                                <div className="market-receipt-item-description-text">{purchasedItem.itemCost.toFixed(2)}</div>
                            </div>
                        </div>
                        <div className="market-receipt-footer">
                            <div className="market-receipt-footer-text">Total: {purchasedItem.totalCost.toFixed(2)}</div>
                            <div className="market-receipt-footer-text">Deducted: {purchasedItem.totalCost.toFixed(2)}</div>
                            <div className="market-receipt-footer-text">Balance: {purchasedItem.balance.toFixed(2)}</div>
                        </div>
                        <button className="market-receipt-button-toggle" onClick={() => setShowReceipt(false)}>Close</button>
                    </div>
                </div>
            }
            <div className="market-category-border">
                <div className="market-category-header">Condiments</div>
                <div className="market-item-list">
                    <div className="market-item-border">
                        <div className="market-item">
                            <div className="market-item-detail">
                                <img src={riceIcon} alt={"rice"} className="market-item-icon" />
                                <div className="market-item-detail-name">Rice</div>
                            </div>
                            <div className="market-item-qtyList">
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 1</div><button className="market-item-qtyButton" onClick={() => buyItem(1, 1)}><img src={cashIcon} alt={"cash"} className="cash-icon" />1.00</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 10</div><button className="market-item-qtyButton" onClick={() => buyItem(1, 10)}><img src={cashIcon} alt={"cash"} className="cash-icon" />10.00</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 100</div><button className="market-item-qtyButton" onClick={() => buyItem(1, 100)}><img src={cashIcon} alt={"cash"} className="cash-icon" />100.00</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="market-item-border">
                        <div className="market-item">
                            <div className="market-item-detail">
                                <img src={soySauceIcon} alt={"rice"} className="market-item-icon" />
                                <div className="market-item-detail-name">Soy Sauce</div>
                            </div>
                            <div className="market-item-qtyList">
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 1</div><button className="market-item-qtyButton" onClick={() => buyItem(2, 1)}><img src={cashIcon} alt={"cash"} className="cash-icon" />0.60</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 10</div><button className="market-item-qtyButton" onClick={() => buyItem(2, 10)}><img src={cashIcon} alt={"cash"} className="cash-icon" />6.00</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 100</div><button className="market-item-qtyButton" onClick={() => buyItem(2, 100)}><img src={cashIcon} alt={"cash"} className="cash-icon" />60.00</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="market-item-border">
                        <div className="market-item">
                            <div className="market-item-detail">
                                <img src={eggIcon} alt={"rice"} className="market-item-icon" />
                                <div className="market-item-detail-name">Egg</div>
                            </div>
                            <div className="market-item-qtyList">
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 1</div><button className="market-item-qtyButton" onClick={() => buyItem(3, 1)}><img src={cashIcon} alt={"cash"} className="cash-icon" />0.75</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 10</div><button className="market-item-qtyButton" onClick={() => buyItem(3, 10)}><img src={cashIcon} alt={"cash"} className="cash-icon" />7.50</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 100</div><button className="market-item-qtyButton" onClick={() => buyItem(3, 100)}><img src={cashIcon} alt={"cash"} className="cash-icon" />75.00</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="market-item-border">
                        <div className="market-item">
                            <div className="market-item-detail">
                                <img src={oilIcon} alt={"rice"} className="market-item-icon" />
                                <div className="market-item-detail-name">Oil</div>
                            </div>
                            <div className="market-item-qtyList">
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 1</div><button className="market-item-qtyButton" onClick={() => buyItem(4, 1)}><img src={cashIcon} alt={"cash"} className="cash-icon" />1.50</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 10</div><button className="market-item-qtyButton" onClick={() => buyItem(4, 10)}><img src={cashIcon} alt={"cash"} className="cash-icon" />15.00</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 100</div><button className="market-item-qtyButton" onClick={() => buyItem(4, 100)}><img src={cashIcon} alt={"cash"} className="cash-icon" />150.00</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="market-item-border">
                        <div className="market-item">
                            <div className="market-item-detail">
                                <img src={pepperIcon} alt={"rice"} className="market-item-icon" />
                                <div className="market-item-detail-name">Pepper</div>
                            </div>
                            <div className="market-item-qtyList">
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 1</div><button className="market-item-qtyButton" onClick={() => buyItem(5, 1)}><img src={cashIcon} alt={"cash"} className="cash-icon" />0.50</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 10</div><button className="market-item-qtyButton" onClick={() => buyItem(5, 10)}><img src={cashIcon} alt={"cash"} className="cash-icon" />5.00</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 100</div><button className="market-item-qtyButton" onClick={() => buyItem(5, 100)}><img src={cashIcon} alt={"cash"} className="cash-icon" />50.00</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="market-item-border">
                        <div className="market-item">
                            <div className="market-item-detail">
                                <img src={saltIcon} alt={"rice"} className="market-item-icon" />
                                <div className="market-item-detail-name">Salt</div>
                            </div>
                            <div className="market-item-qtyList">
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 1</div><button className="market-item-qtyButton" onClick={() => buyItem(6, 1)}><img src={cashIcon} alt={"cash"} className="cash-icon" />0.40</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 10</div><button className="market-item-qtyButton" onClick={() => buyItem(6, 10)}><img src={cashIcon} alt={"cash"} className="cash-icon" />4.00</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 100</div><button className="market-item-qtyButton" onClick={() => buyItem(6, 100)}><img src={cashIcon} alt={"cash"} className="cash-icon" />40.00</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="market-item-border">
                        <div className="market-item">
                            <div className="market-item-detail">
                                <img src={noodleIcon} alt={"rice"} className="market-item-icon" />
                                <div className="market-item-detail-name">Noodles</div>
                            </div>
                            <div className="market-item-qtyList">
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 1</div><button className="market-item-qtyButton" onClick={() => buyItem(7, 1)}><img src={cashIcon} alt={"cash"} className="cash-icon" />1.00</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 10</div><button className="market-item-qtyButton" onClick={() => buyItem(7, 10)}><img src={cashIcon} alt={"cash"} className="cash-icon" />10.00</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 100</div><button className="market-item-qtyButton" onClick={() => buyItem(7, 100)}><img src={cashIcon} alt={"cash"} className="cash-icon" />100.00</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="market-category-border">
                <div className="market-category-header">Materials</div>
                <div className="market-item-list">
                    <div className="market-item-border">
                        <div className="market-item">
                            <div className="market-item-detail">
                                <img src={noodleIcon} alt={"rice"} className="market-item-icon" />
                                <div className="market-item-detail-name">Forge Fuel</div>
                            </div>
                            <div className="market-item-qtyList">
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 1</div><button className="market-item-qtyButton" onClick={() => buyItem(11, 1)}><img src={cashIcon} alt={"cash"} className="cash-icon" />5.00</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 10</div><button className="market-item-qtyButton" onClick={() => buyItem(11, 10)}><img src={cashIcon} alt={"cash"} className="cash-icon" />50.00</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 100</div><button className="market-item-qtyButton" onClick={() => buyItem(11, 100)}><img src={cashIcon} alt={"cash"} className="cash-icon" />500.00</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="market-item-border">
                        <div className="market-item">
                            <div className="market-item-detail">
                                <img src={noodleIcon} alt={"rice"} className="market-item-icon" />
                                <div className="market-item-detail-name">Polish Cloth</div>
                            </div>
                            <div className="market-item-qtyList">
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 1</div><button className="market-item-qtyButton" onClick={() => buyItem(12, 1)}><img src={cashIcon} alt={"cash"} className="cash-icon" />4.00</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 10</div><button className="market-item-qtyButton" onClick={() => buyItem(12, 10)}><img src={cashIcon} alt={"cash"} className="cash-icon" />40.00</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 100</div><button className="market-item-qtyButton" onClick={() => buyItem(12, 100)}><img src={cashIcon} alt={"cash"} className="cash-icon" />400.00</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="market-category-border">
                <div className="market-category-header">Parcels</div>
                <div className="market-item-list">
                    <div className="market-item-border">
                        <div className="market-item">
                            <div className="market-item-detail">
                                <img src={noodleIcon} alt={"rice"} className="market-item-icon" />
                                <div className="market-item-detail-name">Region 1 (Parcel A)</div>
                            </div>
                            <div className="market-item-qtyList">
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 1</div><button className="market-item-qtyButton" onClick={() => buyItem(21, 1)}><img src={cashIcon} alt={"cash"} className="cash-icon" />25.00</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 10</div><button className="market-item-qtyButton" onClick={() => buyItem(21, 10)}><img src={cashIcon} alt={"cash"} className="cash-icon" />250.00</button></div>
                                <div className="market-item-qtyList-costPrice"><div className="market-item-qtyList-cost">Buy 100</div><button className="market-item-qtyButton" onClick={() => buyItem(21, 100)}><img src={cashIcon} alt={"cash"} className="cash-icon" />2,500.00</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NavBar />
        </div >
    );
}

export default Market;