import React, { useState } from 'react';
import { Navigate, BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';

import Home from './pages/home/home';
import Maintenance from './Maintenance';
import Login from './Login';
import Register from './Register';
import Inventory from './pages/inventory/inventory';
import Adventure from './pages/adventure/adventure';
import Battle from './pages/battle/battle';
import Battlefield from './pages/battlefield/battlefield';
import Profile from './pages/profile/profile';
import Headquarters from './pages/headquarters/headquarters';
import Equipment from './pages/equipment/equipment';
import Market from './pages/market/market';
import Guide from './pages/guide/guide';
import Kitchen from './pages/kitchen/kitchen';
import Quest from './pages/quest/quest';
import Order from './pages/order/order';
import ComingSoon from './pages/ComingSoon';

function App() {
  const [isMaintenance] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return sessionStorage.getItem('isAuthenticated') === 'true';
  });

  // Function to handle login, store in both state and localStorage
  const handleLogin = () => {
    setIsAuthenticated(true);
    sessionStorage.setItem('isAuthenticated', 'true'); // Save login state to localStorage
  };

  // const handleLogout = () => {
  //   setIsAuthenticated(false);
  //   sessionStorage.setItem('isAuthenticated', 'false'); // Save login state to localStorage
  // };

  return (
    <Router>
      <div className="content">
        <Routes>
          <Route path="/login" element={isAuthenticated === false ? <Login onLogin={handleLogin} /> : <Navigate to="/home" />} />
          <Route path="/register" element={isAuthenticated === false ? <Register onRegister={handleLogin}/> : <Navigate to="/home" />} />
          <Route path="/home" element={isAuthenticated === false ? <Navigate to="/login" /> : <Home />} />
          <Route path="/adventure" element={isAuthenticated === false ? <Navigate to="/login" /> : <Adventure />} />
          <Route path="/kitchen" element={isAuthenticated === false ? <Navigate to="/login" /> : <Kitchen />} />
          <Route path="/inventory" element={isAuthenticated === false ? <Navigate to="/login" /> : <Inventory />} />
          <Route path="/battle" element={isAuthenticated === false ? <Navigate to="/login" /> : <Battle />} />
          <Route path="/profile" element={isAuthenticated === false ? <Navigate to="/login" /> : <Profile />} />
          <Route path="/order" element={isAuthenticated === false ? <Navigate to="/login" /> : <Order />} />
          <Route path="/quest" element={isAuthenticated === false ? <Navigate to="/login" /> : <Quest />} />
          <Route path="/headquarters" element={isAuthenticated === false ? <Navigate to="/login" /> : <Headquarters />} />
          <Route path="/battlefield" element={isAuthenticated === false ? <Navigate to="/login" /> : <Battlefield />} />
          <Route path="/equipment" element={isAuthenticated === false ? <Navigate to="/login" /> : <Equipment />} />
          <Route path="/market" element={isAuthenticated === false ? <Navigate to="/login" /> : <Market />} />
          <Route path="/guides" element={isAuthenticated === false ? <Navigate to="/login" /> : <Guide />} />
          <Route path="/event" element={isAuthenticated === false ? <Navigate to="/login" /> : <ComingSoon />} />
          <Route path="/*" element={isMaintenance === true ? <Maintenance /> : <Navigate to="/home" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
