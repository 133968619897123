import NavBar from '../../components/navBar/navBar.js';
import AdventureBar from '../../components/adventureBar/adventureBar.js';

import HeaderBar from '../../components/headerBar.js';
import AdventureCard from '../../components/adventureCard/adventureCard.js';

import '../../assets/style.css';

import { useEffect, useState } from 'react';
import axios from 'axios';

function Adventure() {
    // Player Info
    const [username] = useState(() => {
        return sessionStorage.getItem("username");
    });

    // Adventure Status Information
    // const [region, setRegion] = useState(1);
    const [zone, setZone] = useState(0);
    const [idleDMG, setIdleDMG] = useState(0);
    const [timePerKill, setTimePerKill] = useState(0);
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [maxTime, setMaxTime] = useState(0);
    const [stats, setStats] = useState([]);

    // Handle Claim button click
    const handleClaim = async () => {
        if (!username) return console.error("Username not found in session.");

        try {
            const response = await axios.put(`/api/adventure/claim`, { username, zone });
            if (response.data.success) {
                setZone(response.data.data.zoneNo);
                setIdleDMG(response.data.data.idleDMG);
                setTimePerKill(response.data.data.timeElapsed);

                alert(`You have collected your rewards! \nTotal Kills: ${response.data.reward.kills} \nTotal Cash: ${response.data.reward.cash} \nTotal XP: ${response.data.reward.xp} \nTotal Ingredients: ${response.data.reward.ingredient} \nDungeon Key: ${response.data.reward.key} \nRegion 1 Parcel (Tier 1): ${response.data.reward.parcel} \nRegion 1 Token: ${response.data.reward.token} `)
            }
        } catch (error) {
            console.error("Error:", error.response || error.message || error);
        }
    };

    const handleJoin = async (zoneSelected) => {
        if (!username) return console.error("Username not found in session.");

        try {
            const response = await axios.put(`/api/adventure/enter`, { username, zone: zoneSelected });
            if (response.data.success) {
                // setRegion(response.data.data.RegionNo);
                setZone(response.data.data.zoneNo);
                setIdleDMG(response.data.data.idleDMG);
                setTimePerKill((response.data.data.timePerKill > 0) ? response.data.data.timePerKill : 0);
                setTimeElapsed(response.data.data.timeElapsed);
                // setEstKill((response.data.data.timePerKill > 0) ? Math.floor(response.data.data.timeElapsed / response.data.data.timePerKill) : 0);
                setMaxTime(response.data.data.maxTime);
            }
        } catch (error) {
            console.error("Error:", error.response || error.message || error);
        }
    };

    const handleExit = async () => {
        if (!username) return console.error("Username not found in session.");

        try {
            const response = await axios.put(`/api/adventure/enter`, { username, zone: 0 });
            if (response.data.success) {
                // setRegion(response.data.data.RegionNo);
                setZone(response.data.data.zoneNo);
                setIdleDMG(response.data.data.idleDMG);
                setTimePerKill((response.data.data.timePerKill > 0) ? response.data.data.timePerKill : 0);
                setTimeElapsed(response.data.data.timeElapsed);
                // setEstKill((response.data.data.timePerKill > 0) ? Math.floor(response.data.data.timeElapsed / response.data.data.timePerKill) : 0);
                setMaxTime(response.data.data.maxTime);
            }
        } catch (error) {
            console.error("Error:", error.response || error.message || error);
        }
    };

    // Update the data at initial load (Once)
    const fetchAPIData = async () => {
        if (!username) return console.error("Username not found in session.");

        try {
            const response = await axios.get(`/api/adventure/status?username=${username}`);
            if (response.data.success) {
                // setRegion(response.data.data.regionNo);
                setZone(response.data.data.zoneNo);
                setIdleDMG(response.data.data.idleDMG);
                setTimePerKill(response.data.data.timePerKill);
                setTimeElapsed(response.data.data.timeElapsed);
                setMaxTime(response.data.data.maxTime);
                setStats(response.data.data.statistics)
            }
        } catch {
            console.log("error");
        }
    };

    useEffect(() => {
        fetchAPIData();
    });

    // UseEffect for the live updating progress bar and idleKills update
    useEffect(() => {
        const interval = setInterval(() => {
            // Only update time if the player is in a valid zone
            if (zone > 0) {  // Only count time if in a valid zone (zone > 0)
                setTimeElapsed(prevTime => {
                    if (prevTime < maxTime) {  // Update until 2 hours (Idle capped)
                        return prevTime + 1;
                    }
                    clearInterval(interval); // Stop interval once we reach the capped time
                    return prevTime;
                });
            } else {
                // If the player is in an invalid zone (zone == 0), set time to 0
                setTimeElapsed(0);
            }
        }, 1000); // Runs every second

        // Cleanup on component unmount
        return () => clearInterval(interval);
    }, [zone]);  // Dependencies are time, zone, and idleDmg

    function timeFormatter(seconds) {
        if (seconds < 60) {
            return "0:" + ((seconds < 10) ? "0" + seconds : seconds);
        } else if (seconds < (3600)) {
            return Math.floor(seconds / 60) + ":" + ((seconds % 60 < 10) ? "0" + seconds % 60 : seconds % 60);
        } else if (seconds < maxTime) {
            return Math.floor(seconds / 3600) + ":" + (((Math.floor(seconds % 3600 / 60)) < 10) ? "0" + Math.floor(seconds % 3600 / 60) : Math.floor(seconds % 3600 / 60)) + ":" + ((seconds % 60 < 10) ? "0" + seconds % 60 : seconds % 60);
        } else {
            return Math.floor(maxTime / 3600) + ":00:00";
        }
    }

    function timeString(seconds) {
        if (seconds < 60) {
            return seconds + "s";
        } else if (seconds < 3600) {
            return Math.round(seconds / 60) + "m";
        } else {
            return Math.round(seconds / 3600) + "h";
        }
    }

    return (
        <div className="deviceScreenBorder">
            <HeaderBar header={"Adventure"} />
            <div className='adventureGrid'>
                <div className="gridCard-4">
                    <AdventureCard id={1} zone={zone} idleDMG={idleDMG} timePerKill={timePerKill} timeElapsed={timeElapsed} maxTime={maxTime} stats={stats.Clucky} onJoin={() => handleJoin(1)} onClaim={() => handleClaim()} onExit={() => handleExit()} />
                </div>
                <div className="gridCard-4">
                    <AdventureCard id={2} zone={zone} idleDMG={idleDMG} timePerKill={timePerKill} timeElapsed={timeElapsed} maxTime={maxTime} stats={stats.Garly} onJoin={() => handleJoin(2)} onClaim={() => handleClaim()} onExit={() => handleExit()} />
                </div>
                <div className="gridCard-4">
                    <AdventureCard id={3} zone={zone} idleDMG={idleDMG} timePerKill={timePerKill} timeElapsed={timeElapsed} maxTime={maxTime} stats={stats.Crabby} onJoin={() => handleJoin(3)} onClaim={() => handleClaim()} onExit={() => handleExit()} />
                </div>
                <div className="gridCard-4">
                    <AdventureCard id={4} zone={zone} idleDMG={idleDMG} timePerKill={timePerKill} timeElapsed={timeElapsed} maxTime={maxTime} stats={stats.Chilly} onJoin={() => handleJoin(4)} onClaim={() => handleClaim()} onExit={() => handleExit()} />
                </div>
            </div>
            <NavBar />
        </div >
    );
}

export default Adventure;
